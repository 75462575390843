import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';

const PortraitGallery = () => {
    const [currentIndex, setCurrentIndex] = useState(1);
    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const [dragOffset, setDragOffset] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const [progress, setProgress] = useState(0);
    const containerRef = useRef(null);
    const progressInterval = useRef(null);
    const autoplayInterval = useRef(null);
    const [data, setData] = useState([])
    const fetched = useRef(false); // Prevent multiple calls

    const originalImages = data;

    const images = [
        originalImages[originalImages.length - 1],
        ...originalImages,
        originalImages[0]
    ];

    const minSwipeDistance = 50;
    const autoplayDuration = 2000;
    const progressUpdateInterval = 16; // ~60fps

    useEffect(() => {
        startAutoplay();
        return () => {
            clearInterval(autoplayInterval.current);
            clearInterval(progressInterval.current);
        };
    }, []);

    useEffect(() => {
        if (fetched.current) return; // Exit if already fetched
        fetched.current = true;
    
        async function fetchData() {
            try {
                const response = await axios.get("https://minf-backend.vercel.app/latestUpdate");
                if (response?.data) {
                    const res = response.data[0].images.map(img =>
                        img.replace("https://minf-uploads.s3.ap-south-1.amazonaws.com/", "https://d13vfm13fhaqg1.cloudfront.net/")
                    );
                    setData(res);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
    
        fetchData();
    }, []);
     // Empty dependency array ensures this runs only once
    
    useEffect(() => {
        if (isTransitioning) {
            const timer = setTimeout(() => {
                setIsTransitioning(false);
                if (currentIndex === 0) {
                    setCurrentIndex(images.length - 2);
                } else if (currentIndex === images.length - 1) {
                    setCurrentIndex(1);
                }
            }, 300);
            return () => clearTimeout(timer);
        }
    }, [currentIndex, isTransitioning, images.length]);

    const startAutoplay = () => {
        setProgress(0);
        clearInterval(autoplayInterval.current);
        clearInterval(progressInterval.current);

        // Progress bar update
        progressInterval.current = setInterval(() => {
            setProgress(prev => {
                if (prev >= 100) return 0;
                return prev + (100 / (autoplayDuration / progressUpdateInterval));
            });
        }, progressUpdateInterval);

        // Image transition
        autoplayInterval.current = setInterval(goToNext, autoplayDuration);
    };

    const onTouchStart = (e) => {
        if (isTransitioning) return;
        clearInterval(autoplayInterval.current);
        clearInterval(progressInterval.current);
        setTouchEnd(null);
        setTouchStart(e.targetTouches[0].clientX);
        setIsDragging(true);
        setDragOffset(0);
    };

    const onTouchMove = (e) => {
        if (!isDragging || isTransitioning) return;
        const currentTouch = e.targetTouches[0].clientX;
        const diff = touchStart - currentTouch;
        setDragOffset(diff);
        setTouchEnd(currentTouch);
    };

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd || isTransitioning) return;

        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;

        if (isLeftSwipe || isRightSwipe) {
            setProgress(0);
            if (isLeftSwipe) {
                goToNext();
            } else {
                goToPrev();
            }
        }

        setIsDragging(false);
        setDragOffset(0);
        startAutoplay();
    };

    const goToNext = () => {
        setIsTransitioning(true);
        setCurrentIndex(prev => prev + 1);
        setProgress(0);
        startAutoplay();
    };

    const goToPrev = () => {
        setIsTransitioning(true);
        setCurrentIndex(prev => prev - 1);
        setProgress(0);
        startAutoplay();
    };


    return (
        <div className="w-full md:max-w-full md:container max-w-md mx-auto relative h-[60vh] overflow-hidden">
            <div
                className="absolute inset-0 flex items-center justify-center"
                onTouchStart={onTouchStart}
                onTouchMove={onTouchMove}
                onTouchEnd={onTouchEnd}
                ref={containerRef}
            >
                <div className="relative h-full aspect-[9/16] bg-gray-100">
                    {/* Images container with smooth transitions */}
                    <div
                        className={`absolute inset-0 flex ${isTransitioning ? 'transition-transform duration-300' : ''} ease-out`}
                        style={{
                            transform: `translateX(calc(-${currentIndex * 100}% + ${isDragging ? -dragOffset : 0}px))`
                        }}
                    >
                        {images.map((image, index) => (
                            <div
                                key={index}
                                className="min-w-full h-full overflow-hidden aspect-[9/16] object-cover bg-gray-700 flex-shrink-0"
                            >
                                <img
                                    src={image}
                                    alt={`Portrait ${index}`}
                                    className="w-full h-full object-cover object-center"
                                />
                            </div>
                        ))}
                    </div>

                    {/* Navigation buttons */}
                    <button
                        onClick={() => {
                            if (!isTransitioning) {
                                goToPrev();
                            }
                        }}
                        className="absolute left-4 top-1/2 -translate-y-1/2 bg-black/50 text-white p-2 rounded-full z-20 hover:bg-black/70 transition-colors"
                    >
                        ←
                    </button>
                    <button
                        onClick={() => {
                            if (!isTransitioning) {
                                goToNext();
                            }
                        }}
                        className="absolute right-4 top-1/2 -translate-y-1/2 bg-black/50 text-white p-2 rounded-full z-20 hover:bg-black/70 transition-colors"
                    >
                        →
                    </button>

                    {/* Circular progress indicator */}
                    <div className="absolute bottom-4 right-2 -translate-x-1/2 flex items-center gap-2 z-20">
                        <div className="relative w-6 h-6">
                            <svg className="w-full h-full transform -rotate-90">
                                <circle
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="white"
                                    strokeWidth="2"
                                    fill="none"
                                    className="opacity-30"
                                />
                                <circle
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="white"
                                    strokeWidth="2"
                                    fill="none"
                                    strokeDasharray={`${2 * Math.PI * 10}`}
                                    strokeDashoffset={`${2 * Math.PI * 10 * (1 - progress / 100)}`}
                                    className="transition-all duration-100"
                                />
                            </svg>
                        </div>
                    </div>


                    {/* Edge gradients */}
                    <div className="absolute left-0 top-0 bottom-0 w-16 bg-gradient-to-r from-black/20 to-transparent pointer-events-none z-10" />
                    <div className="absolute right-0 top-0 bottom-0 w-16 bg-gradient-to-l from-black/20 to-transparent pointer-events-none z-10" />
                </div>
            </div>
        </div>
    );
};

export default PortraitGallery;